var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CookiesConsentModalManageMySettings" },
    [
      _c("div", { staticClass: "is-flex mb-5" }, [
        _c(
          "div",
          {
            staticClass: "is-clickable",
            attrs: { "data-qa-ta": "goBackArrow" },
            on: { click: _vm.onGoBack },
          },
          [_c("b-icon", { attrs: { icon: "arrow-left", size: "is-small" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "CookiesConsentModalManageMySettings-text has-text-weight-semibold ml-3",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("cookies_modal_manage_my_settings")) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "CookiesConsentModalManageMySettings-collapsable",
          attrs: {
            animation: "slide",
            open: _vm.isCollapsableOpen,
            "aria-id": "collapsableForGoogleAnalytics",
          },
          on: {
            "update:open": function ($event) {
              _vm.isCollapsableOpen = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "is-flex is-align-items-center py-4",
                      attrs: {
                        role: "button",
                        "aria-controls": "collapsableForGoogleAnalytics",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("b-switch", {
                        attrs: {
                          "data-qa-ta": "googleAnalyticsOption",
                          type: "is-primary",
                          size: "is-small",
                        },
                        model: {
                          value: _vm.isGoogleAnalyticsAccepted,
                          callback: function ($$v) {
                            _vm.isGoogleAnalyticsAccepted = $$v
                          },
                          expression: "isGoogleAnalyticsAccepted",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v("Google Analytics")]),
                      _vm._v(" "),
                      _c("b-icon", {
                        staticClass: "ml-auto",
                        class: props.open && "icon-close",
                        attrs: { icon: "chevron-down" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "CookiesConsentModalManageMySettings-collapsable-content has-background-white-ter p-5",
            },
            [
              _c(
                "p",
                { attrs: { "data-qa-ta": "googleAnalyticsOptionInformation" } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("cookies_modal_manage_google_analytics")) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "is-capitalized is-block ml-auto mt-6",
          attrs: { type: "is-primary", "data-qa-ta": "acceptCookiesSettings" },
          on: {
            click: function ($event) {
              return _vm.$emit("accept-settings", {
                isGoogleAnalyticsAccepted: _vm.isGoogleAnalyticsAccepted,
              })
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.$t("accept")) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }