import Emitter from 'tiny-emitter/instance';

interface GlobalBus {
    on(event: string, callback: Function, ctx?: any): unknown;
    once(event: string, callback: Function, ctx?: any): unknown;
    emit(event: string, ...args: any[]): unknown;
    off(event: string, callback?: Function): unknown;
}

export const globalBus: GlobalBus = Emitter;
