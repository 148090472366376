<script>
import { globalBus } from '@/infrastructure/plugins/globalBus.ts';

export default {
    name: 'ErrorBoundary',
    errorCaptured(error) {
        globalBus.emit('error', { error });

        // Stop error propagation
        return false;
    },
    render() {
        return this.$slots.default;
    }
};
</script>
