import { LOCALE } from '@/infrastructure/locales';
import { Currency } from '@/infrastructure/utils/CurrencyFormat';
import { NullableParam } from '@/services/nullableParam';
import { SharedMetadata } from '@/services/sharedMetadata';

export type Seller = {
    externalId: string;
    name: string;
    createdAt: string;
    physicalAddress: PhysicalAddress;
    marketplaceConfiguration: MarketplaceConfiguration;
    services: Service[];
    locale: LocaleData;
};

export type SellerProfile = {
    name: string;
    phoneNumber: string;
    email: string;
    physicalAddress: SellerAddress;
    pharmacist?: PharmacistProfile;
    web: SellerProfileWeb;
    socialLinks: SocialLinksProfile;
    images: ProfileImages;
};

export type ProfileImages = ProfileImage[];

export type ProfileImage = {
    type: ImageType;
    path: string;
};

export enum ImageType {
    BACKGROUND = 'BACKGROUND',
    LOGO = 'LOGO',
    PHARMACIST = 'PHARMACIST'
}

export type SocialLinkProfile = {
    type: SocialLinkType;
    value: string;
};

export type SocialLinksProfile = SocialLinkProfile[];

export enum SocialLinkType {
    FACEBOOK = 'FACEBOOK',
    TWITTER = 'TWITTER',
    LINKEDIN = 'LINKEDIN',
    WHATSAPP = 'WHATSAPP'
}

type SellerProfileWeb = {
    url: string;
};

export type SellerProfileDescriptions = Description[];

type Description = {
    locale: DescriptionLocale;
    description: string;
};

type DescriptionLocale = {
    id: string;
    code: LOCALE;
};

export type UpdateSellerProfileData = {
    name: string;
    phoneNumber: string;
    email: string;
    addressLine: string;
    city: string;
    province: string;
    zipCode: string;
    ownerName: string;
    ownerLastName: string;
    licenseNumber: string;
    webUrl: string;
    socialLinks: UpdateSocialLink[];
    descriptions: UpdateSellerProfileDescription[];
    images: ProfileImages;
};

type UpdateSocialLink = {
    type: SocialLinkType;
    value: string;
};

export type UpdateSellerProfileDescription = {
    localeId: string;
    description: string;
};

export type SellerAddress = {
    addressLine: string;
    city: string;
    countryCode: SellerCountryCode;
    province: string;
    zipCode: string;
};

type PharmacistProfile = {
    name: string;
    lastName: string;
    licenseNumber: string;
};

export type Service = {
    code: SellerService;
    status: ServiceStatus;
    configurationData?: [] | Record<string, unknown>;
};

type PhysicalAddress = {
    countryCode: SellerCountryCode;
};

type LocaleData = {
    code: LOCALE;
};

export enum ServiceStatus {
    SUBSCRIBED = 'subscribed',
    UNSUBSCRIBED = 'unsubscribed',
    PENDING = 'pending'
}

export enum SellerService {
    SAME_DAY = 'same_day',
    MARKETPLACE = 'marketplace',
    HYPERWALLET = 'hyperwallet',
    ADYEN = 'adyen',
    STOCKHUB = 'stockhub',
    ELECTRONIC_RECEIPT = 'electronic_receipt',
    FULFILMENT = 'fulfillment',
    GOLD_PARTNER = 'gold_partner',
    OTC = 'otc'
}

export type Condition = {
    type: ConditionType;
    valueType: ConditionValueType;
    value: string;
};

export enum ConditionValueType {
    FILE = 'file',
    FREE_TEXT = 'free_text',
    LINK = 'link'
}

export enum ConditionType {
    TERMS_CONDITIONS = 'terms_conditions',
    IMPRINT = 'terms_conditions_imprint',
    PRIVACY_POLICY = 'privacy_policy'
}

export type ConditionFile = {
    fileMimeType: string;
    fileContent: string;
};

export type UploadSellerImageResponse = {
    type: ImageType;
    path: string;
};

export type AdyenOnboardingEndpoint = {
    url: string;
};

export enum AdyenServiceConfigurationDataStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    ACTIVE = 'ACTIVE'
}

export type GetInvoicesGroupsRequest = {
    page: number;
    itemsPerPage: number;
    year: NullableParam<string>;
    month: NullableParam<string>;
};

export type GetInvoicesGroupsResponse = {
    data: InvoicesGroup[];
    metadata: InvoicesGroupMetadata;
};

export type InvoicesGroup = {
    year: number;
    month: number;
    amount: number;
    currency: Currency;
    invoices: Invoice[];
    status: InvoiceStatus;
};

export type InvoicesGroupMetadata = {
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
};

export type Invoice = {
    id: string;
    serialNumber: string;
    factorType: InvoiceFactorType;
    invoiceDate: string;
    billedFrom: string;
    billedTo: string;
    amount: number;
    currency: Currency;
    numberOfReports: number;
};

export enum InvoiceFactorType {
    ISSUED = 'ISSUED',
    RECEIVED = 'RECEIVED'
}

export enum InvoiceStatus {
    PAID = 'PAID',
    PENDING = 'PENDING'
}

export type GetInvoiceFileResponse = {
    filename: string;
    fileMimeType: string;
    fileContent: string;
};

export type GetInvoiceReportsFileResponse = {
    fileName: string;
    fileMimeType: string;
    fileContent: string;
};

export type SearchInvoicesRequest = {
    filters: SearchInvoicesFilters;
};

type SearchInvoicesFilters = {
    serialNumber: string;
    year: NullableParam<string>;
    month: NullableParam<string>;
};

export type SearchInvoicesResponse = {
    data: Invoice[];
    metadata: SharedMetadata;
};

export enum SellerCountryCode {
    GERMANY = 'DE',
    SWITZERLAND = 'CH',
    SPAIN = 'ES',
    FRANCE = 'FR',
    PORTUGAL = 'PT',
    ITALY = 'IT',
    CYPRUS = 'CY',
    POLAND = 'PL',
    AUSTRIA = 'AT',
    NETHERLANDS = 'NL'
}

type MarketplaceConfiguration = {
    id: string;
    type: MarketplaceConfigurationType;
};

export enum MarketplaceConfigurationType {
    HYBRID = 'hybrid',
    MANAGED = 'managed',
    PURE = 'pure'
}

export type Validation = {
    type: SellerValidationType;
    validated: boolean;
};

export enum SellerValidationType {
    SHOP_INFORMATION = 'shop_information',
    FISCAL_INFORMATION = 'fiscal_information',
    HOLIDAYS = 'holidays',
    BUSINESS = 'business'
}
