import { LOCALE } from './index';
import { Language } from './language';

export const getLanguageFromLocale = (locale: LOCALE): Language => {
    const languageByLocale: Record<LOCALE, Language> = {
        [LOCALE.de_CH]: Language.DE,
        [LOCALE.de_DE]: Language.DE,
        [LOCALE.en_GB]: Language.EN,
        [LOCALE.es_ES]: Language.ES,
        [LOCALE.fr_CH]: Language.FR,
        [LOCALE.fr_FR]: Language.FR,
        [LOCALE.it_IT]: Language.IT,
        [LOCALE.pt_PT]: Language.PT
    };

    return languageByLocale[locale];
};
