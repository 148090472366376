import { LOCALE } from './index';

export enum Language {
    EN = 'en',
    DE = 'de',
    FR = 'fr',
    PT = 'pt',
    ES = 'es',
    IT = 'it'
}

enum LanguageDescription {
    ENGLISH = 'English',
    GERMAN = 'Deutsch',
    SPANISH = 'Español',
    PORTUGUESE = 'Português',
    FRENCH = 'Français',
    ITALIAN = 'Italiano'
}

export const getLanguageDescriptionByLocale = (localeValue: LOCALE): LanguageDescription | string => {
    const languageDescriptionByLocale: Record<LOCALE, LanguageDescription> = {
        [LOCALE.de_CH]: LanguageDescription.GERMAN,
        [LOCALE.de_DE]: LanguageDescription.GERMAN,
        [LOCALE.fr_CH]: LanguageDescription.FRENCH,
        [LOCALE.es_ES]: LanguageDescription.SPANISH,
        [LOCALE.it_IT]: LanguageDescription.ITALIAN,
        [LOCALE.pt_PT]: LanguageDescription.PORTUGUESE,
        [LOCALE.en_GB]: LanguageDescription.ENGLISH,
        [LOCALE.fr_FR]: LanguageDescription.FRENCH
    };

    return languageDescriptionByLocale[localeValue] || '';
};
