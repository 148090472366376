<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import { SnackbarProgrammatic as SnackBar } from 'buefy';
import { i18n } from '@/infrastructure/plugins/i18n';
import { globalBus } from '@/infrastructure/plugins/globalBus.ts';
import UnAuthorizedError from '@/infrastructure/errors/UnAuthorizedError.ts';

export default {
    created() {
        globalBus.on('error', this.showErrorNotification);
        globalBus.on('success', this.showSuccessNotification);
        globalBus.on('warning', this.showWarningNotification);
    },
    beforeUnmount() {
        globalBus.off('error');
        globalBus.off('success');
        globalBus.off('warning');
    },
    methods: {
        showErrorNotification({ message, error } = {}) {
            if (!message) {
                message = i18n.t('error_occurred');
            }

            SnackBar.open({
                message: `
                    <i class="icon mdi mdi-alert-circle mdi-24px"></i>
                    <p data-qa-ta="errorSnackbarMessage">${message}</p>
                `,
                type: 'is-danger',
                actionText: null,
                duration: 6000,
                position: 'is-bottom-right',
                queue: false
            });

            if (error) {
                if (error instanceof UnAuthorizedError) {
                    return;
                }
                // eslint-disable-next-line no-console
                console.error(error); // Used to log the errors on DataDog
            }
        },
        showSuccessNotification(message) {
            SnackBar.open({
                message: `
                <i class="icon mdi mdi-check-circle mdi-24px"></i>
                <p data-qa-ta="successSnackbarMessage">${message}</p>
                `,
                type: 'is-success',
                actionText: null,
                duration: 3500,
                position: 'is-bottom-right',
                queue: false
            });
        },
        showWarningNotification(message) {
            SnackBar.open({
                message: `
                <i class="icon mdi mdi-alert mdi-24px"></i>
                <p data-qa-ta="warningSnackbarMessage">${message}</p>
                `,
                type: 'is-warning',
                actionText: null,
                duration: 3500,
                position: 'is-bottom-right',
                queue: false
            });
        }
    }
};
</script>
